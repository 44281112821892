import React from "react";
import colors from "../../config/colors";
import Icon from "../Icon";
import { CheckboxContainer,TheBox } from "./styles";

function Checkbox({label,value,setValue,disabled=false},isBorder=false) {
  return <CheckboxContainer>
    <TheBox
    isBorder={isBorder}
     checked={value} disabled={disabled} onClick={()=>{
      !disabled && setValue(!value);
    }}>
      <Icon type="tick" size="14px" color={colors.background}/>
    </TheBox>
    {label}
  </CheckboxContainer>;
}
export default Checkbox;