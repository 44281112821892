import React from "react";
import colors from "../../config/colors";
import { ButtonSpacer } from "../Button/styles";
import Icon from "../Icon";
import { TableContainer, TableHead, TableHeaderCell, TableBody, TableRow, TableCell, TableHeaderCellContent } from "./styles";

/**
 * 
 * The classic table component.
 * Takes in columns and rows and renders them as a table.
 */
function Table({ columns, rows }) {
  const [sortOptions, setSortOptions] = React.useState({
    column: null,
    direction: null,
  });
  function sortOnColumn(column){
    if(column === sortOptions.column){
      setSortOptions({
        ...sortOptions,
        direction: sortOptions.direction === "asc"? "desc" : "asc",
      });
    }
    else{
      setSortOptions({
      ...sortOptions,
        column: column,
        direction: "asc",
      });
    }
  }
  
  return <TableContainer>
    <TableHead>
      {
        columns.map((column) => 
          <TableHeaderCell 
            key={"header_" + column.key} 
            onClick={()=>{
              if(column.sortable !==false){
                sortOnColumn(column.key);
              }
             }}
          >
            <TableHeaderCellContent>
            {column.title}

            {
              sortOptions.column === column.key &&
                <>
                  <ButtonSpacer/>
                  {
                    sortOptions.direction === "asc" ? 
                      <Icon type={"collapse"} color={colors.text} size="10"/> 
                      : 
                      <Icon type={"expand"} color={colors.text} size="10"/>
                  }
                </>
                
            }
            </TableHeaderCellContent>
          </TableHeaderCell>
        )
      }
    </TableHead>
    <TableBody>
      
      {
        rows
        .sort((a,b)=>{
          if(sortOptions.column === null){
            return 0
          }
          else{
            let valueA = a[sortOptions.column] || "";
            let valueB = b[sortOptions.column] || "";
            return ((sortOptions.direction==="asc") ? 1 : -1 ) * valueA.toString().localeCompare(valueB.toString())
          }
        })
        .map((row, index) =>
          <TableRow key={"row_" + index}>
            {
              columns.map((column) =>
                <TableCell
                  key={"cell_" + column.key + "_" + index}
                >
                  {
                    column.render ?
                      <column.render value={row[column.key]} row={row}>
                        {row[column.key]}
                      </column.render>
                      :
                      row[column.key]
                  }
                </TableCell>
              )
            }
          </TableRow>
        )
      }
    </TableBody>
  </TableContainer>;
}
export default Table;