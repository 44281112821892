import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../../../../common/styles";
import colors from "../../../../../../../../config/colors";

export const MeetingContainer = styled.div`
    display:flex;
    width:100%;
    height:calc(100vh - 340px - ${panelSpacing*2}px);
    box-sizing:border-box;
    @media (max-width:640px) {
     flex-direction:column;
    }
`;
export const MeetingSidebar = styled.div`
width: 200px;
min-width: 200px;
background-color: #fff;
box-sizing: border-box;
z-index: 9;
padding:10px;
@media (max-width:640px) {
     width: 100%;
     min-width: 100%;
     height: 160px;
    }
   
`;

export const MeetingBody = styled.div`
background-color: #EFEFEF;
height:100%;
padding: 10px 0px 10px 10px;
box-sizing: border-box;
width:100%;
overflow:hidden;

@media (max-width:640px) {
  padding:${(props) => props.isWidth && "0"};
     width: ${(props) => props.isWidth && "100%"};
     background-color: ${(props) => props.isWidth && "transparent"};
    }


`;
export const BodyContainer = styled.div`
background-color: #FFFFFF;
border-radius: 10px;
width:100%;
height:${(props) => (props.isTeam ? `calc(100vh - 410px)`  : "100%")};
padding-top: 10px;
padding-bottom: 10px;
overflow:hidden;
${Scrollable}

@media (max-width:640px) {
width:100%;

    }

`;
export const BoardSideBarHeader = styled.div`
  display:flex;
  justify-content:space-between;
  align-items: center;
  z-index: 9;
  padding-bottom: 10px;

`;
export const BodyTitle = styled.div`
    font-size: 20px;
    text-transform: capitalize;
    color: #b1b1b1;
    padding: 10px;

`;
export const StyledIframe = styled.iframe`
  width:100%;
  height:100%;
  border:none;


`;


export const MeetingsNotification = styled.h3`
text-align:center;
// margin-top:20%;

`;
export const Action = styled.div`
display:flex;
justify-content:center;
margin-bottom:50px;

`;

export const PillsTeamContainer = styled.div`
display:flex;
justify-content:center;
@media (max-width:640px) {
  margin-top:10px;
    }

`;
//export manager card
export const CardContainer= styled.div`
max-width:700px;
height: fit-content;
display: flex;
flex-wrap: wrap;
justify-content:flex-start;
over-flow:scroll;
${Scrollable}
padding:10px;
gap: 5px;
background:${colors.secondary};


` 

export const Card= styled.div`
padding: 30px;
background:#fff;
height:160px;
margin-left:10px;
margin-right:10px;
margin-top:5px;
border-radius:10px;
box-shadow: 0px 2px 4px 0px #0004;
border-top: ${props=> props.color ? props.color:'9px solid #44449B'};
width:260px;


` 
export const HorizentalLine= styled.div`
width:100%
height:10px;
background:${props=>props.color ? props.color :'#fff'};
margin



` 

export const ImageWrapper= styled.div`
display: flex;
justify-content:space-between;
align-items:center;
`
export const InfoWraper= styled.div`
display: flex;
justify-content:space-between;
align-items:center;
width:100%;
gap:10px;
background:${colors.secondary};
padding:5px;
border-radius:8px;
`
export const Subtitle= styled.span`

font-size:${props=>props.font ? props.font :'16px'};
font-weight:${props=>props.font && "bold"};
padding-top:${props=>props.font && "30px"};
padding:${props=>props.font && "10px"};


`
export const Actions= styled.div`
  
    width: 100%;
    padding-top: 2rem;
    display: flex;
    justify-content: flex-end;
`
export const ClindalyContainer= styled.div`
  width:70vw;
  height:70vh;
`
