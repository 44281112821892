import { attachPromiseToToast } from "../Store/Toasts/actions";
import callAPI from "../api/apiCaller";
import endpoints from "../api/endpoints";

export function dateToUnixTimeStamp(dateString) {
  const date = new Date(dateString);
  return Math.floor(date.getTime() / 1000);
}


export const TimetoLocalString=(unixTimestamp)=>{

  // const unixTimestamp = 1708634220; // Example Unix timestamp
  const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
  
  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }).format(date);
  
  return formattedTime;
}


// formate the date to show time zone  with day and year
export function unixStampToDate(unixTimeStamp) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // Use 12-hour format with AM/PM
    timeZone: 'America/Los_Angeles',
  };
  const date = new Date(unixTimeStamp * 1000).toLocaleString("en-US", options);
  return date + ' PST';
}



export const DisplayDate = (dateString) => {
  const date = new Date(dateString);

  const dateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const timeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

  const formattedTime = date.toLocaleTimeString('en-US', timeFormatOptions);
  const formattedDate = date.toLocaleDateString('en-US', dateFormatOptions);

  return `${formattedTime}, ${formattedDate}`;
};

export function formatOnlyDate(dateString) {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const dateParts = dateString.split("-");
  const year = dateParts[0];
  const monthIndex = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);

  const formattedDate = `${day} ${months[monthIndex]}, ${year}`;

  return formattedDate;
}

const originalDate = "2024-04-24";
const formattedDate = formatDate(originalDate);
// console.log(formattedDate); // Output: 24 April, 2024



export function unixTImeStampToIsoDate(unixTimeStamp){
  const date = new Date(unixTimeStamp * 1000).toISOString("UTC").replace('Z', '');
  return date;

}
// export function formatDate(date) {
//   date = new Date(date);
//   let hours = date.getHours();
//   let ampm = "am";
//   if (hours > 12) {
//     hours -= 12;
//     ampm = "pm";
//   }
//   let minutes = date.getMinutes();
//   let timeString = `${hours < 10 ? "0" : ""}${hours}:${
//     minutes < 10 ? "0" : ""
//   }${minutes}${ampm}`;
//   console.log(ampm, minutes, hours);
//   let day = date.getDate();
//   let months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   let month = months[date.getMonth()];
//   let year = date.getFullYear();
//   let dateString = `${day} ${month}, ${year}`;
//   return timeString + " " + dateString;
// }

export function formatDate(date) {
  date = new Date(date);
  let hours = date.getHours();
  let ampm = hours >= 12 ? 'pm' : 'am'; // Adjusted logic
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // “0” should be “12" in 12-hour format
  let minutes = date.getMinutes();
  let timeString = `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}${ampm}`;
  let day = date.getDate();
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let month = months[date.getMonth()];
  let year = date.getFullYear();
  let dateString = `${day} ${month}, ${year}`;
  return timeString + ' ' + dateString;
}

export function formatDateForTask(date) {
  date = new Date(date + "t00:00:00");
  date.setHours(11, 59, 0);
  let hours = date.getHours();
  let ampm = "pm";

  let minutes = date.getMinutes();
  let timeString = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}${ampm}`;

  let day = date.getDate();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = months[date.getMonth()];
  let year = date.getFullYear();
  let dateString = `${day} ${month}, ${year}`;
  return timeString + " " + dateString;
}


export function formatsDateForTask(date, time = true) {
  let taskDate = new Date(date + "T00:00:00");

  let hours = taskDate.getHours();
  let minutes = taskDate.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let timeString = `${hours}:${minutes} ${ampm}`;

  let day = taskDate.getDate();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = months[taskDate.getMonth()];
  let year = taskDate.getFullYear();
  let dateString = `${day} ${month}, ${year}`;

  if (time) {
    let currentTime = new Date();
    let currentHours = currentTime.getHours();
    let currentMinutes = currentTime.getMinutes();
    let currentAmPm = currentHours >= 12 ? "pm" : "am";
    currentHours = currentHours % 12;
    currentHours = currentHours ? currentHours : 12; // Handle midnight (0 hours)
    currentMinutes = currentMinutes < 10 ? "0" + currentMinutes : currentMinutes;
    let currentTimeString = `${currentHours}:${currentMinutes} ${currentAmPm}`;
    timeString = currentTimeString;
  }

  return timeString + " " + dateString;
}


// export function NumberOfDaysBetweenDates(dates){
//   if (dates.length) {
//     dates.sort();
//     dates.reverse();
//     const mostRecentDate = new Date (dates[0]*1000);
//     const leastRecentDate= new Date (dates[1]*1000);
//     const timeDifferenceMs =
//     mostRecentDate.getTime() - leastRecentDate.getTime();

//     return Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

//   }
// }
export function NumberOfDaysBetweenDates(dates){
  if (dates.length) {
    dates.sort();
    dates.reverse();
    const mostRecentDate = new Date (dates[0]*1000);
    const leastRecentDate= new Date (dates[1]*1000);
    const timeDifferenceMs =
    mostRecentDate.getTime() - leastRecentDate.getTime();

    return Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));

  }
}

export function NumberOfDaysBetweenDatesAndCurrentDate(dates) {

  if (dates.length) {
    dates.sort();
    dates.reverse();
    const createdAtDate = new Date(dates[0] * 1000);
    const todaysDate = new Date();
    const timeDifferenceMs =
     todaysDate.getTime() - createdAtDate.getTime();

    return Math.ceil(timeDifferenceMs / (1000 * 60 * 60 * 24));
  }
}

export function addHourToTime(date){
  const startDate = new Date(date);
  const startDateTime = new Date(startDate).getTime();
  const oneHourInMillis = 60 * 60 * 1000; // 1 hour in milliseconds
  const endDateTime = new Date(startDateTime + oneHourInMillis);
  const formattedEndDate = endDateTime.toDateString();
  const date3= new Date(formattedEndDate)
  return formattedEndDate
}

export function addHourToUnixTimeStamp(unixDate){
const unixTimestamp = unixDate; 
const timestampInMilliseconds = unixTimestamp * 1000;
const newTimestampInMilliseconds = timestampInMilliseconds + 3600000;
const newUnixTimestamp = newTimestampInMilliseconds / 1000;
return newUnixTimestamp
}

export function formatDescription(string) {
  let parts = string.split("\n");
  return parts.map((part) => (
    <>
      {part}
      <br />
    </>
  ));
}

export function formatsDescription(string) {
  let parts = string.split("\n");
  return parts.join("<br/>");
}

export function formatMessageDate(date) {
  date = new Date(date);
  let hours = date.getHours();
  let ampm = "am";
  if (hours > 12) {
    hours -= 12;
    ampm = "pm";
  }
  let minutes = date.getMinutes();
  let timeString = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}${ampm}`;

  let day = date.getDate();
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let month = months[date.getMonth()];
  let dateString = `${day} ${month}`;
  let todaysDate = new Date();
  todaysDate.setHours(0);
  todaysDate.setMinutes(0);
  todaysDate.setSeconds(0);
  if (date.getFullYear() < new Date().getFullYear()) {
    return dateString + " '" + date.getFullYear().toString().slice(2);
  } else if (date < todaysDate) {
    return dateString;
  } else {
    return timeString;
  }
}
export function timeToDateValue(time) {
  let date = new Date(time);
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  return (
    year +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    (day < 10 ? "0" + day : day)
  );
}

export async function checkIfTokenValid(dispatch) {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    return callAPI(dispatch, "checkAccessToken", { accessToken }).then(
      (retrievedUser) => {
        return retrievedUser;
      }
    );
  }
  return Promise.reject(false);
}

export function createInitialValid(fields) {
  let isValid = {};
  fields.forEach((field) => {
    isValid[field] = null;
  });
  return isValid;
}

export function updateValid(isValid, setIsValid, field) {
  return (newValue) => {
    setIsValid({
      ...isValid,
      [field]: newValue,
    });
  };
}
export function updateValidTogether(isValid, setIsValid, obj, validators = {}) {
  let newValid = {};
  Object.keys(isValid).forEach((field) => {
    newValid[field] = validators[field]
      ? validators[field](obj[field])
      : validateText(obj[field]);
  });
  setIsValid(newValid);
}
export function validateIsValid(isValid) {
  return isValid && Object.keys(isValid).every((key) => isValid[key] === true);
}
export function validateNumber(value) {
  if (value === 0) {
    return null;
  }
  return typeof value === "number" || !isNaN(value);
}
export function validateDate(value) {
  return (
    value.split("-").length === 3 &&
    value.split("-").every((value) => validateNumber(value)) &&
    parseInt(value.split("-")[0]) >= 1970
  );
}
export function validateText(value) {
  if (!value || value?.length === 0) {
    return null;
  }
  // let safeRegex = /[!^*_+\[\];\\|<>\/?]+/;
  // let test = value.length > 0 && !safeRegex.test(value);
  return true;
}

export function validateString(value) {
  if (!value || value?.length === 0) {
    return null;
  }
 
  return true;
}


export function validateArray(itemValidate = Boolean) {
  return (value) => {
    if (value.length === 0) return null;
    return value.every((item) => itemValidate(item));
  };
}
export function validateEmail(value) {
  let textTest = validateText(value);
  // if (!textTest) {
  //   return textTest;
  // }
  let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  return emailRegex.test(value);
}
export function isMobile() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
}

export async function uploadImageToS3(dispatch, apiName, { url, file }) {
  let apiInfo = endpoints[apiName];
  const imageResponse = await fetch(url, {
    ...apiInfo,
    body: file,
  });
  return imageResponse;
}

export function validatePhoneNumber(phoneNumber) {
  const phoneNumberRegex =
    /^(\+\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;
  return phoneNumberRegex.test(phoneNumber);
}

export function hasSpecialCharacter(password) {
  const hasSpecialCharacters = /[^\w\s]/;
  return hasSpecialCharacters.test(password);
}

export function hasAtleastOneNumber(password) {
  const hasAtleastOneNumber = /\d/;
  return hasAtleastOneNumber.test(password);
}

export function atLeastLengthOfEight(password) {
  if (password?.length >= 8) {
    return true;
  }
  return false;
}

export function maximumLengthOfTwenty(password) {
  if (password?.length <= 20) {
    return true;
  }
  return false;
}

export function validatePassword(password) {
  const passwordRegex = /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/;
  return passwordRegex.test(password);
}

export function validateUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export function convertDateToCustomFormat(inputDate) {
// Assuming you have a Unix timestamp in seconds
const unixTimestamp = inputDate; // Replace with your actual timestamp

// Create a JavaScript Date object from the Unix timestamp (multiply by 1000 to convert to milliseconds)
const date = new Date(unixTimestamp * 1000);

// Extract the components: year, month, day, hours, minutes, and seconds
const year = date.getFullYear();
const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
const day = date.getDate().toString().padStart(2, '0');
const hours = date.getHours().toString().padStart(2, '0');
const minutes = date.getMinutes().toString().padStart(2, '0');
const seconds = date.getSeconds().toString().padStart(2, '0');

// Format the date and time in the iCalendar format
const icsFormattedDateTime = `${year}${month}${day}T${hours}${minutes}${seconds}`;
return icsFormattedDateTime
}

// profile image issue after updating here send just key split url
export  function getImageKeyFromURL(image) {
  const urlParts = image?.split('/');
  return urlParts[urlParts?.length - 1];
}

export function isImageUrl(url){
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1
};

// export const convertToLinks = (text, user) => {
//   if (!text) {
//     return ''; // Return an empty string if text is null or undefined
//   }

//   const urlRegex = /(https?:\/\/[^\s<>"']+)/g;
//   const linkedText = text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" style="color:${user ? '#fff' : ''}">${url}</a>`);

//   return linkedText;
// };

export const convertToLinks=(text, user) =>{
  if (!text) {
        return ''; // Return an empty string if text is null or undefined
      }

  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\b(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\b)/ig;
  return text.replace(urlRegex, function(url) {
      // Check if the URL starts with "http", "ftp", or "file" and prepend "http://" if it does not
      const prefixedUrl = /^https?:\/\//i.test(url) ? url : `http://${url}`;
      // const linkStyles = user ? 'color: #fff; text-decoration: underline;' : 'text-decoration: underline;';
      // return '<a href="' + prefixedUrl + '" target="_blank" style="${linkStyles}">' + url + '</a>';
      const linkStyles = user ? 'color: #fff; text-decoration: underline;' : 'text-decoration: underline;';
      return `<a href="${prefixedUrl}" target="_blank" style="${linkStyles}">${url}</a>`;
  });
}
  export function isResponsive() {
    return window.matchMedia("(max-width: 991px)").matches;
}