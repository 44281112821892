import React from 'react'
import Pills from '../../../common/Pills'
import { ActivityContainer,ButtonWraper } from './style'
import { PillContainer, SearchBoxContainer } from '../Programs/ProgramContent/ProgramParticipants/styles'
import TextInput from '../../../common/TextInput'
import Button from '../../../common/Button'
import ActivityContent from './ActivityContent'
import AddParticipants from '../Programs/popups/AddParticipants'

export default function Activity() {

    const [currentPage, setCurrentPage] = React.useState("UPCOMING");
    const [searchParticipant, setSearchParticipant] = React.useState("");
  const [isAddParticipantsOpen, setIsAddParticipantsOpen] = React.useState(false);
  const [refreshInvites, setRefreshInvites] = React.useState(false);
   

    
  return (
    <>
    <PillContainer reverse={true}>
    <SearchBoxContainer>
      <TextInput placeholder="Search for Participant" value={searchParticipant} setValue={setSearchParticipant}/>
    </SearchBoxContainer>
    <Pills
    value={currentPage}
    setValue={setCurrentPage}
    options={[
      {
        label: "New",
        value: "UPCOMING"
      },
      {
        label: "Completed",
        value: "COMPLETE"
      },
      {
        label: "Unfulfilled",
        value: "PAST"
      }
    ]}
  />
    <ButtonWraper>
    <Button icon="add" text="Add Participants" onClick={() => {
      setIsAddParticipantsOpen(true)
    }} />
    </ButtonWraper>
  </PillContainer>
  <ActivityContent currentPage={currentPage} searchParticipant={searchParticipant} />
  {
      isAddParticipantsOpen && <AddParticipants  closeModal={() => setIsAddParticipantsOpen(false)} />
    }
</>
  )
}
