import React, { useEffect } from "react";
import {
  ActiveParticipantsContainer,
  MentorHeading,
  MentorHeader,
  Info,
  Image,
  Name,
  Actions,
  Meta,
  MenteeHeader,
  ParticipantsContainer,
  UnassignedList,
  UnassignedContainer,
  ViewChatIconContainer,
  UnassignedActionContainer,
  ActiveParticipantsWrapper,
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../../../api/apiCaller";
import Accordian from "../../../../../../common/Accordian";
import Button from "../../../../../../common/Button";
import { ButtonSpacer } from "../../../../../../common/Button/styles";
import Loading from "../../../../../../common/Loading";
import ReassignMentee from "../../../popups/ReassignMentee";
import { Context } from "../../../../../../Store";
import UserInfo from "../../../popups/UserInfo";
import TeamParticipant from "../../../TeamParticipant";
import Modal from "../../../../../../common/Modal";
import { SectionActions } from "../../../../Profile/LoginSecurity/styles";
import SpecificTeam from "../../MyTeams/SpecificTeam";
import MyTeams from "../../MyTeams";

function ActiveParticipants({
  searchText,
  specificTeamProgram,
  setSpecificTeamProgram,
}) {
  React.useEffect(() => {
    programInformation();
  }, []);

  const params = useParams();
  const navigate = useNavigate();
  const [participants, setParticipants] = React.useState(null);
  const [isReassignOpen, setIsReassignOpen] = React.useState(false);
  const [isReassignOpenTeam, setIsReassignOpenTeam] = React.useState(false);
  const [isViewOpen, setIsViewOpen] = React.useState(false);
  const [refreshParticipants, setRefreshParticipants] = React.useState(false);
  const [isDeleteMember, setIsDeletMember] = React.useState(false);
  const [program, setProgram] = React.useState([]);
  const [teams, setTeams] = React.useState([]);

  const Participants = (data) => {
    // console.log("program__", program)
    // console.log("program ", program.programType)
    let activeParticipants = [];

    data?.unAssignedMentees
      ? (activeParticipants = [
          ...data?.mentors,
          { name: "unAssignedMentees", mentees: data?.unAssignedMentees },
        ])
      : (activeParticipants = [
          { name: "unAssignedMentees", mentees: data?.unAssignedMembers },
        ]);

    return activeParticipants;
  };
  const { state, dispatch } = React.useContext(Context);

  const programInformation = () => {
    callAPI(dispatch, "getProgramInformation", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgram(info.data);
    });
  };

  function getMyTeam() {
    callAPI(dispatch, "getMyteams", {
      id: params.selectedProgram,
    }).then((res) => {
      setTeams(res?.data);
    });
  }

  function deleteProgram(id) {
    callAPI(dispatch, "DeleteMember", {
      tid: +params.selectedProgram,
      id: id,
    }).then(() => {
      setIsDeletMember(false);
      getParticipant();
    });
  }

  const getParticipant = () => {
    callAPI(dispatch, "getProgramParticipants", {
      id: params.selectedProgram,
    }).then((res) => {
      setParticipants(Participants(res.data.active));
    });
  };
  React.useEffect(() => {
    getMyTeam();
    setParticipants(null);
    getParticipant();
  }, [refreshParticipants]);

  if (participants === null) {
    return (
      <ActiveParticipantsContainer>
        <Loading loadingItem="Participants" />
      </ActiveParticipantsContainer>
    );
  }

  return (
    <ActiveParticipantsWrapper>
      <ActiveParticipantsContainer>
        {program && program.programType === "TEAM" ? (
          <ParticipantsContainer>
            <TeamParticipant
              setSpecificProgram={setSpecificTeamProgram}
              specificTeamProgram={specificTeamProgram}
            />
          </ParticipantsContainer>
        ) : (
          <ParticipantsContainer>
            <MentorHeading>
              {program.programType === "OPEN_NETWORK"
                ? "Meetings that have Occurred"
                : "Assigned Mentors and Mentees"}
            </MentorHeading>
            <ButtonSpacer />
            <ButtonSpacer />
            <Accordian
              multiOpen={true}
              defaultOpen={false}
              sections={participants
                .filter((mentor) => {
                  if (mentor?.name === "unAssignedMentees") {
                    return false;
                  }

                  if (
                    mentor.participant.firstName
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return true;
                  }

                  if (
                    mentor.mentees.length &&
                    mentor.mentees?.some((mentee) =>
                      mentee.participant.firstName
                        ?.toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                  ) {
                    return true;
                  }

                  return false;
                })
                .map((mentor) => {
                  return {
                    title: (
                      <MentorHeader>
                        <Info>
                          {
                            <Image
                              src={
                                mentor.participant.image
                                  ? mentor.participant.image
                                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                              }
                            />
                          }
                          <Name>
                            {`${mentor?.participant.firstName} ${mentor?.participant.lastName}`}
                            <Meta>Mentor</Meta>
                          </Name>
                        </Info>
                        {mentor.participant.firstName !=
                          "unAssignedMentees" && (
                          <Actions>
                            <Meta>
                              Total Tracked Hours : {mentor.totalTrackHours} |
                              Total Mentees: {mentor.mentees.length}
                            </Meta>
                            <ButtonSpacer />
                            <ViewChatIconContainer>
                              <Button
                                icon="view"
                                onClick={() => {
                                  setIsViewOpen(mentor.participantId);
                                }}
                              />
                              <ButtonSpacer />
                              <Button
                                icon="chat"
                                onClick={() => {
                                  navigate(`/messages/${mentor.participant.uuid}`);
                                }}
                              />
                            </ViewChatIconContainer>
                          </Actions>
                        )}
                      </MentorHeader>
                    ),
                    content: (
                      <>
                        {mentor.mentees.length !== 0 &&
                          mentor.mentees
                            .filter((mentee) => {
                              if (
                                mentor.participant.firstName
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              ) {
                                return true;
                              }
                              if (
                                mentee.participant.firstName
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              ) {
                                return true;
                              }
                            })
                            .map((mentee) => {
                              return (
                                <MenteeHeader>
                                  <Info>
                                    {
                                      <Image
                                        src={
                                          mentee.participant.image
                                            ? mentee.participant.image
                                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                        }
                                      />
                                    }
                                    <Name>
                                      {mentee.participant.firstName +
                                        " " +
                                        mentee.participant.lastName}
                                      <Meta>Mentee</Meta>
                                    </Name>
                                  </Info>
                                  <Actions>
                                    <Meta>
                                      Tracked Hours : {mentee.trackedHours}
                                    </Meta>
                                    <ButtonSpacer />
                                    <ViewChatIconContainer>
                                      <Button
                                        icon="view"
                                        onClick={() => {
                                          setIsViewOpen(mentee.participantId);
                                        }}
                                      />
                                      <ButtonSpacer />
                                      <Button
                                        icon="chat"
                                        onClick={() => {
                                          navigate(
                                            `/messages/${mentee.participant.uuid}`
                                          );
                                        }}
                                      />

                                      <ButtonSpacer />
                                      {program &&
                                        program?.programType ===
                                          "1_1_NETWORK" && (
                                          <Button
                                            text="Reassign"
                                            variant="primary"
                                            onClick={() => {
                                              setIsReassignOpen(
                                                mentee.participantId
                                              );
                                            }}
                                          />
                                        )}
                                    </ViewChatIconContainer>
                                  </Actions>
                                </MenteeHeader>
                              );
                            })}
                      </>
                    ),
                  };
                })}
            />
          </ParticipantsContainer>
        )}
        <UnassignedContainer>
          <MentorHeading>
            {program.programType === "OPEN_NETWORK"
              ? "All Mentees"
              : program.programType === "TEAM"
              ? "Unassigned Member"
              : "Unassigned Mentees"}
          </MentorHeading>
          <ButtonSpacer />
          <UnassignedList>
            {participants
              .filter((mentor) => {
                if (mentor.name === "unAssignedMentees") {
                  return true;
                }
                return false;
              })
              .map((mentor) => {
                return (
                  <>
                    {mentor.mentees.length !== 0 &&
                      mentor.mentees
                        .filter((mentee) => {
                          if (
                            mentee.participant?.firstName
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          ) {
                            return true;
                          }
                        })
                        .map((mentee) => {
                          return (
                            <MenteeHeader>
                              <Info>
                                {
                                  <Image
                                    src={
                                      mentee.participant.image
                                        ? mentee.participant.image
                                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                    }
                                  />
                                }
                                <Name>
                                  {mentee.participant.firstName +
                                    " " +
                                    mentee.participant.lastName}
                                  <Meta>
                                    {program.programType === "TEAM"
                                      ? ""
                                      : "Mentee"}
                                  </Meta>
                                </Name>
                              </Info>
                              <UnassignedActionContainer>
                                <ButtonSpacer />
                                <Button
                                  icon="view"
                                  onClick={() => {
                                    setIsViewOpen(mentee.participantId);
                                  }}
                                />
                                <ButtonSpacer />
                                <Button
                                  icon="chat"
                                  onClick={() => {
                                    navigate(
                                      `/messages/${mentee.participant.uuid}`
                                    );
                                  }}
                                />
                                <ButtonSpacer />
                                <Button
                                  icon="trash"
                                  onClick={() => {
                                    setIsDeletMember(mentee.participantId);
                                  }}
                                />
                                <ButtonSpacer />
                                {program &&
                                  program?.programType === "1_1_NETWORK" && (
                                    <>
                                      <Button
                                        text="Assign"
                                        variant="primary"
                                        onClick={() => {
                                          setIsReassignOpen(
                                            mentee.participantId
                                            
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                {program && program?.programType === "TEAM" && (
                                  <>
                                    <Button
                                      text="Assign"
                                      variant="primary"
                                      onClick={() => {
                                        setIsReassignOpenTeam(
                                          mentee.participantId
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </UnassignedActionContainer>
                            </MenteeHeader>
                          );
                        })}
                  </>
                );
              })}
          </UnassignedList>
        </UnassignedContainer>

        {isReassignOpen && (
          <ReassignMentee
            menteeId={isReassignOpen}
            closeModal={(isRefresh) => {
              if (isRefresh) {
                setRefreshParticipants(!refreshParticipants);
              }
              setIsReassignOpen(false);
            }}
          />
        )}

        {isReassignOpenTeam && (
          <ReassignMentee
            program={program}
            team={teams}
            getMyTeam={getMyTeam}
            programInformation={programInformation}
            menteeId={isReassignOpenTeam}
            closeModal={(isRefresh) => {
              if (isRefresh) {
                setRefreshParticipants(!refreshParticipants);
              }
              setIsReassignOpenTeam(false);
            }}
          />
        )}

        {isDeleteMember && (
          <Modal
            title={"Are you sure you want to remove member"}
            onClose={() => setIsDeletMember(false)}
          >
            <SectionActions>
              <Button
                icon={"trash"}
                variant="danger"
                onClick={() => {
                  deleteProgram(isDeleteMember);
                }}
                text="Yes"
              />
              <ButtonSpacer />
              <Button
                // icon="tick"
                variant="primary"
                onClick={() => setIsDeletMember(false)}
                text="Cancel"
              />
            </SectionActions>
          </Modal>
        )}

        {isViewOpen && (
          <UserInfo
            userId={isViewOpen}
            closeModal={() => {
              setIsViewOpen(false);
            }}
          />
        )}
      </ActiveParticipantsContainer>
    </ActiveParticipantsWrapper>
  );
}
export default ActiveParticipants;
