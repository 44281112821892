import React from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../../../../api/apiCaller";
import Button from "../../../../../../../../common/Button";
import { ButtonSpacer } from "../../../../../../../../common/Button/styles";
import Labelize from "../../../../../../../../common/Labelize";
import Loading from "../../../../../../../../common/Loading";
import Modal from "../../../../../../../../common/Modal";
import Table from "../../../../../../../../common/Table";
import { TableActions, TableContainer } from "../../../../../../../../common/Table/styles";
import TextInput from "../../../../../../../../common/TextInput";
import { createInitialValid, formatDate, updateValid, validateDate, validateIsValid, validateNumber } from "../../../../../../../../common/utils";
import { TimesheetContainer, StatusCell, AddTimesheetButtonContainer, TableWrapper } from "./styles";
import { Context } from "../../../../../../../../Store";
import { useSearchParams  } from 'react-router-dom'
import { getUserInfo } from "../../../../../../../../Store/User/accessors";
function Timesheet() {
  const [searchParams] = useSearchParams();
  const role = searchParams.get('role')
  let params = useParams();
  const [timesheet, setTimesheet] = React.useState(null);
  const [isAddNewOpen, setIsAddNewOpen] = React.useState(false);
  const [isDeletingRecord, setIsDeletingRecord] = React.useState(false);
  const [isAddingRecord, setAddingRecord] = React.useState(false);
  const [isApprovingRecord, setIsApprovingRecord] = React.useState(false);
  const [isAddFormValid, setIsAddFormValid] = React.useState(createInitialValid(["date","hours"]));
  const {state,dispatch} = React.useContext(Context);
  const recipientId=params.connectionId
  const [newRecord, setNewRecord] = React.useState({
    date: "",
    hours: 0 
  });

  function getProgramSheet(){
  const recipientId=params.connectionId
  callAPI(dispatch,role=='mentee'?"getMenteeTrackHour":"getMentorTrackHours", { id: params.selectedProgram, rid:getUserInfo(state).id }).then((retrievedTimesheet) => {
      if(retrievedTimesheet.isResponseOk){
      setTimesheet(retrievedTimesheet.data);
      }
    });
  }
  React.useEffect(() => {
    getProgramSheet()
  }, []);
  if (timesheet === null) {
    return <Loading loadingItem="Timesheet" />
  }

  function deleteTimesheetRecord(id) {
    setIsDeletingRecord(id)
    callAPI(dispatch,"deleteTimesheetRecord", { id }).then(() => {
      setIsDeletingRecord(false);
      getProgramSheet()
      
    })
  }
  function approveTimesheetRecord(notification) {
    setIsApprovingRecord(notification.id);
    callAPI(dispatch,"approveTimesheetRecord", { ...notification,  }).then(() => {
      setIsApprovingRecord(false);
       getProgramSheet()
    })
  }
  function createTimesheetRecord() {
    setAddingRecord(true);
    let request={
      ...newRecord,
      hours:parseInt(newRecord.hours),
      programId:+params.selectedProgram,
      recipientId:+recipientId,
    }
    callAPI(dispatch,"addTimesheetRecord", request).then(() => {
      setAddingRecord(false);
      getProgramSheet()
      setIsAddNewOpen(false);
      setNewRecord({
        date: "",
        hours: 0
      })
    })
  }
  return <TimesheetContainer>
    <AddTimesheetButtonContainer>
      <Button text="Add New Record" icon="add" onClick={() => {
        setIsAddNewOpen(true);
      }} />
    </AddTimesheetButtonContainer>
    <TableWrapper>
    <Table columns={[
      {
        title: "Date",
        key: "date",
      },
      {
        title: "Hours",
        key: "hours",
        render: ({ value }) => <b>{value}</b>
      },
      {
        title: "Requested Time",
        key: "createdAt",
        render: ({ value }) => {
          return formatDate(value)
        }
      },
      {
        title: "Approved Time",
        key: "timeApproved",
        render: ({ value }) => {
          return value ? formatDate(value) : "-"
        }
      },
      {
        title: "Status",
        key: "status",
        render: ({ value }) => {
          return <StatusCell value={value}>{value}</StatusCell>
        }
      },
      role=='mentor' && {
        title: "Actions",
        key: "-",
        sortable: false,
        render: ({ row }) => {
          return <TableActions>
            {
              row.status === "pending" && <>
                <Button
                  onClick={() => {
                    approveTimesheetRecord(row);
                  }}
                  icon={isApprovingRecord===row.id ? "loading":"tick"}
                />
                <ButtonSpacer />
              </>
            }
            <Button
              onClick={() => {
                deleteTimesheetRecord(row.id);
              }}
              icon={isDeletingRecord===row.id ? "loading":"trash"}
            />
          </TableActions>
        }
      },
    ]}
      rows={timesheet}
    />
    </TableWrapper>
    {
      isAddNewOpen && <Modal
        title={"Add New Timesheet Record"}
        onClose={() => {
          setIsAddNewOpen(false);
        }}
        actions={[
          {
            icon: "cross",
            variant: "secondary",
            text: "Cancel",
            onClick: () => {
              setIsAddNewOpen(false);
            }
          },
          {
            icon: isAddingRecord ? "loading":"add",
            variant: "primary",
            disabled:!validateIsValid(isAddFormValid),
            text: "Add",
            onClick: () => {
              createTimesheetRecord();
            }
          }
        ]}>
        <Labelize label="Date">
          <TextInput 
            value={newRecord.date} 
            type="date"
            isValid={isAddFormValid["date"]}
            validator={validateDate}
            setIsValid={updateValid(isAddFormValid,setIsAddFormValid,["date"])}
            setValue={(newDate) => {
              setNewRecord({
                ...newRecord,
                date: newDate
              })
          }} />
        </Labelize>
        <ButtonSpacer />
        <Labelize label="Hours">
          <TextInput 
          value={newRecord.hours} 
          isValid={isAddFormValid["hours"]}
          setIsValid={updateValid(isAddFormValid,setIsAddFormValid,["hours"])}
          validator={(value)=>{
            return validateNumber(value) && value > 0
          }}
          setValue={(newHours) => {
            setNewRecord({
              ...newRecord,
              hours: newHours
            })
          }} />
        </Labelize>
      </Modal>
    }

  </TimesheetContainer>;
}
export default Timesheet;