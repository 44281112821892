import React, { useState } from "react";
import Button from "../../../common/Button";
import { ButtonSpacer } from "../../../common/Button/styles";
import Labelize from "../../../common/Labelize";
import TextInput from "../../../common/TextInput";
import {
  LoginContainer,
  LoginForm,
  ButtonContainer,
  LoginPageOption,
  LogoContainer,
  Header,
  Heading,
  DevString,
  DropDownButton,
  DropDownWrapper,
  DropDownContent,
  DropDownItem,
} from "./styles";
import { Context } from "../../../Store";
import { validateIsValid, validateNumber } from "../../../common/utils";
import { LoginPageConfigurations } from "./config";
import gitInfo from "../../../../gitInfo/gitinfo.json";
import features from "../../../config/features";
import colors from "../../../config/colors";
import Logo from "../../../common/Logo";
import { useNavigate } from "react-router-dom";
import PhoneNumberField from "../../../common/drop-down";
function Login() {
  const [phoneData, setPhoneData] = useState({
    contryCode: "+44",
    phoneNumber: "",
  });
  const handleChange = (e) => {
    setPhoneData({
      ...phoneData,
      [e.target.name]: e.target.value,
    });
    // setIsValid({ ...isValid, "phoneNumber": validateNumber(phoneData.countryCode+phoneData.phoneNumber) });
  };

  const countryCodes = ["+1", "+44", "+61", "+91", "+86"];
  const navigate = useNavigate();
  const [mode, setMode] = React.useState(
    window.location.pathname.includes("forget-password")
      ? "resetPassword"
      : window.location.pathname.includes("verification")
      ? "verification"
      : "login"
  );

  const [form, setForm] = React.useState({
    email: "",
    password: "",
  });
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isValid, setIsValid] = React.useState({
    email: null,
    password: null,
  });

  React.useEffect(() => {
    let initialState = LoginPageConfigurations[mode].initialState;
    let initialIsValid = {};
    initialState &&
      Object.keys(initialState).forEach((key) => {
        initialIsValid[key] = null;
        if (key === "id") {
          initialIsValid[key] = true;
          initialState[key] = window.location.pathname.split("/").slice(-1)[0];
        }
      });
    setIsValid(initialIsValid);
    setForm(initialState);
    if (
      window.location.pathname.includes("verification") &&
      window.location.pathname.split("/").slice(-1)[0] &&
      mode === "verification"
    ) {
      config.action(
        setIsProcessing,
        { id: window.location.pathname.split("/").slice(-1)[0] },
        dispatch,
        setMode,
        navigate
      );
    }
  }, [mode]);

  const { dispatch } = React.useContext(Context);
  let config = LoginPageConfigurations[mode];

  return (
    <LoginContainer>
      <LogoContainer>
        <Logo height={window.innerWidth * 0.05} color={colors.background} />
      </LogoContainer>
      <LoginForm>
        {mode !== "login" && (
          <>
            <Button
              onClick={() => {
                setMode("login");
              }}
              icon="back"
            />
            <ButtonSpacer />
          </>
        )}
        <Header>
          <Heading>{config.title}</Heading>
        </Header>
        {config?.form &&
          config.form.map((element) => {
            return (
              <>
                {" "}
                <Labelize
                  label={element.label}
                  mode={mode}
                  labelkey={element.key}
                  isValid={isValid[element.key]}
                >
                  {element.key === "phoneNumber" ? (
                    <>
                      <PhoneNumberField
                        countryCodes={countryCodes}
                        countryCode={"+44"}
                        phoneNumber={phoneData}
                        onChange={handleChange}
                        setValue={(newValue) => {
                          setForm({
                            ...form,
                            [element.key]: newValue,
                          });
                        }}
                        setIsValid={(newIsValid) => {
                          setIsValid({ ...isValid, [element.key]: newIsValid });
                        }}
                      />
                    </>
                  ) : (
                    <TextInput
                      placeholder={element.placeholder}
                      isValid={isValid[element.key]}
                      setIsValid={(newIsValid) => {
                        setIsValid({ ...isValid, [element.key]: newIsValid });
                      }}
                      validator={(newValue) =>
                        element.validator(newValue, form)
                      }
                      hideInstruction={element.hideInstruction}
                      value={form[element.key]}
                      type={element.type}
                      mode={mode}
                      setValue={(newValue) => {
                        setForm({
                          ...form,
                          [element.key]: newValue,
                        });
                      }}
                    />
                  )}
                </Labelize>
                <ButtonSpacer />
              </>
            );
          })}
        <ButtonSpacer />
        <ButtonContainer>
          <Button
            text={config.actionText}
            variant="primary"
            disabled={!validateIsValid(isValid)}
            onClick={() =>
              config.action(setIsProcessing, form, dispatch, setMode, navigate)
            }
            icon={isProcessing ? "loading" : ""}
          />
        </ButtonContainer>
      </LoginForm>
      {mode === "login" && (
        <>
          <ButtonSpacer />
          <LoginPageOption
            onClick={() => {
              setMode("createUser");
            }}
          >
            New User? Sign up here!
          </LoginPageOption>
          <ButtonSpacer />

          <LoginPageOption
            onClick={() => {
              setMode("inializeResetPassword");
            }}
          >
           Forget Password
          </LoginPageOption>
        </>
      )}

      {mode === "verification" && (
        <>
          {/* <ButtonSpacer />
          <LoginPageOption
            onClick={() => {
              setMode("createUser");
            }}
          >
            New User? Sign up here!
          </LoginPageOption>
          <ButtonSpacer /> */}
          <LoginPageOption
          // onClick={() => {
          //   setMode("inializeResetPassword");
          // }}
          >
          
            Account Verifcation
          </LoginPageOption>
        </>
      )}
      {/* {gitInfo.branch !== "main" && (
        <DevString>
          This is running {gitInfo.branch} branch of One Mauve UI. <br />
          This was deployed on {gitInfo.time}. <br />
          Last commit was {gitInfo.commit} : {gitInfo.message}
          <br />
          Features list:
          {Object.keys(features).map((feature) => {
            return (
              <div>
                {features[feature] ? "✅" : "❌"}{" "}
                {feature.charAt(0).toUpperCase()}
                {feature.slice(1)}
              </div>
            );
          })}
        </DevString>
      )} */}
    </LoginContainer>
  );
}
export default Login;
