import styled from "styled-components";
import { Scrollable, headerSize, panelSpacing } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ProgramContainer = styled.div`
    padding-top:${props=> props.Top ? '60%' :'' };
    padding-left:${props=> props.Top ? '10%' :'' };
    background-color:${colors.background};
    border-radius:${panelSpacing}px;
    box-sizing:border-box;
    height:calc(100vh - ${headerSize+2*panelSpacing}px);
`;

export const Header = styled.div`
    display:flex;
    padding:${panelSpacing}px;
    align-items:center;
    justify-content:space-between;
`;
export const Section = styled.div`
    display:flex;
    align-items:center;
`;
export const Title = styled.div`
    font-size:24px;
    margin-left:20px;
    font-weight:bold;
`;
export const Body = styled.div`
    border-top:solid 1px ${colors.secondary};
    padding:${panelSpacing}px;
    height:calc(100vh - ${headerSize + 2*panelSpacing + 2*panelSpacing + 40}px);
    box-sizing:border-box;
    overflow-x:hidden;
    overflow-y:scroll;

  @media (max-width: 991px) {
    height:calc(100vh - ${headerSize + 2*panelSpacing + 2*panelSpacing + 290}px);
  }
`;
export const TableContainerOuter = styled.div`
    ${Scrollable};
`;
export const Image = styled.img`
    width: 250px;
    height: 250px;
    object-fit:cover;
    margin-left:${panelSpacing}px;
    border-radius:${panelSpacing}px;
`;
export const Form = styled.div`
    width:calc(100% - 250px - ${panelSpacing}px);
`;

export const Avatar= styled.img`
width: 30px;
    height: 30px;
    object-fit:cover;
    margin-left:${panelSpacing}px;
    border-radius:${panelSpacing}px;
`

export const  BookingHeading = styled.div`
margin-bottom: 1rem;
font-size: 16px;
font-weight: bold;
`;

export const NoprogramContainer= styled.div`
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ButtonsWraper= styled.div`
display:flex;
padding-top: 20px;
margin-inline: 8rem;
justify-content: flex-start;
margin-bottom:${prop=>prop.bottom}px;
`

export const RequestsKeyHeaders = styled.p`
font-style:${props=>props.fontStyles ? props.fontStyles : ''} ;
margin-top:${props=>props.marginSpace ? props.marginSpace : ''}px ;
  white-space: nowrap;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 991px) {
    width:${props=>props.widthSize ? props.widthSize : '200'}px ;
    font-size:${props=>props.size ? props.size : ''}px ;
  }
  margin: 0;
  padding-right: 5px;
  a {
    color: #000; 
    text-transform: capitalize;
    
  }
`;