import React, { useEffect } from "react";
import { HeaderContainer, LogoContainer, HeaderOption, HeaderSection, IconOption, UserOption, UserImage, ProfileDropdown, ProfileOption, ProfileBorder, Premium } from "./styles";
import Icon from "../../../common/Icon";
import colors from "../../../config/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../Store";
import { getUserInfo } from "../../../Store/User/accessors";
import Notifications from "./Notifications";
import { hasNewNotification } from "../../../Store/Notifications/accessors";
import { headerSize } from "../../../common/styles";
import features from "../../../config/features";
import Logo from "../../../common/Logo";
import { ButtonSpacer } from "../../../common/Button/styles";
import callAPI from "../../../api/apiCaller";
import Modal from "../../../common/Modal";
import  PremiumSubscription  from "../Programs/ProgramContent/ProgramHome/PremiumSubscription";
import { socket } from "../../../common/chat-socket";
import {  getUnreadMsg } from "../../../Store/UnreadMessage/accessors";
import {  markAsReadMessage } from "../../../Store/UnreadMessage/actions";
import { ConversationList } from "../../../Store/ConversationList/actions";
import { GroupConversationList } from "../../../Store/GroupConversationList/actions";
import { markAsReadPersonalMessage } from "../../../Store/PersonalMessageCount/actions";

function Header() {
  const location = useLocation();
  let activePage = location.pathname.split("/")[1];
  const [unReadMessage, setunReadMessage] = React.useState(0);
  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);
  const [isPremiumOpen, setIsPremiumOpen] = React.useState(false);
  const [profileDropdownShown, setProfileDropdownShown] = React.useState(false);
  const [groupMessageIndicator, setGroupMessageIndicator] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const navigate = useNavigate();

  function logOut() {
    localStorage.setItem("accessToken", "")
    callAPI(dispatch, "logout", {})
    window.location.href = "/app";
  }



  function hasUnreadMessage(dispatch, setunReadMessage) {
    let unReadMessage, groupMessageIndicator;

    // First, fetch the total unread count of messages
    callAPI(dispatch, "unReadMessage").then((res) => {
        const totalUnreadCount = res.data.totalUnreadCount;
        setunReadMessage(totalUnreadCount);
        unReadMessage = totalUnreadCount; // Store the value for later use

        // After getting the unread count, check for unread group messages
        callAPI(dispatch, "getGroupMessageList").then((res) => {
            const hasUnreadGroupMessage = res.data.some(sms =>
                sms.messages.some(msg =>
                    msg?.userId !== getUserInfo(state)?.id && !msg?.readBy?.includes(getUserInfo(state).id)
                )
            );
            groupMessageIndicator = hasUnreadGroupMessage; // Store the value for later use

            // Dispatch the action after both asynchronous operations complete
            dispatch(markAsReadMessage(unReadMessage, groupMessageIndicator));
        }).catch(error => {
            console.error("Error fetching group messages:", error);
        });
    }).catch(error => {
        console.error("Error fetching unread message count:", error);
    });
}




  React.useEffect(() => {
    // Listen for notification events from the server
    socket.on('notifications', (msg) => {
      if(msg.notification.type==="message"){
        hasUnreadMessage(dispatch, setunReadMessage);

      }
      
    });
    hasUnreadMessage(dispatch, setunReadMessage);
    
  }, []);

  React.useEffect(() => {
    callAPI(dispatch, "getMessageList",).then((res) => {
      const messages = Array.isArray(res.data) ? res.data : [];
      dispatch(ConversationList(messages));
      const unreadMessagesCount = messages.filter(message => message?.lastMessage?.isRead === false && message?.lastMessage?.user?.id !== getUserInfo(state).id).length;
      dispatch(markAsReadPersonalMessage(unreadMessagesCount))
    });
  },[])

  React.useEffect(() => {
    callAPI(dispatch, "getGroupMessageList").then((res) => {
      dispatch(GroupConversationList(res.data));
    });
  },[])


  return <><HeaderContainer>
    <HeaderSection>
      <LogoContainer onClick={() => {
        navigate("/programs");
      }}>
        <Logo height={15} color={colors.background} accent={colors.background} />

      </LogoContainer>

      {
        getUserInfo(state)?.roles[0] === "admin" ? <>
          <HeaderOption
            active={activePage === "programbrowser"}
            to="/programbrowser"
            onClick={() => {
              navigate("/programbrowser");
            }}>
            Program Browser
          </HeaderOption>
          <HeaderOption
            active={activePage === "userbrowser"}
            to="/userbrowser"
            onClick={() => {
              navigate("/userbrowser");
            }}>
            User Browser
          </HeaderOption>
          {/* <HeaderOption
            active={activePage === "controlPanel"}
            to="/controlPanel"
            onClick={() => {
              navigate("/controlPanel/home");
            }}>
            Control Panel
          </HeaderOption> */}
        </>
          :
          <>
            {/* <HeaderOption
              active={activePage === "programs"}
              to="/programs"
              onClick={() => {
                navigate("/programs");
              }}>
              My Programs
            </HeaderOption> */}
            {features.activity && <HeaderOption
              active={activePage === "activity"}
              to="/activity"
              onClick={() => {
                navigate("/activity");
              }}>
              Activity
            </HeaderOption>}
          </>
      }
    </HeaderSection>
    <HeaderSection>
      {
        getUserInfo(state)?.roles[0] !== "admin" ? <>

          <Premium
            active={isPremiumOpen}
            onClick={() => {
              setIsPremiumOpen(!isPremiumOpen);
            }}
          >
            <Icon type={"premium"} color={isPremiumOpen ? colors.primary : colors.background} size={0.4 * headerSize} />
          </Premium>
          <IconOption
            active={isNotificationsOpen}
            onClick={() => {
              setIsNotificationsOpen(!isNotificationsOpen);
            }}
          >
            <Icon type={hasNewNotification(state) ? "notification-active" : "notification"} color={isNotificationsOpen ? colors.primary : colors.background} size={0.4 * headerSize} />
          </IconOption>
          <IconOption
            active={activePage === "messages"}
            onClick={() => {
              navigate("/messages");
            }}>
            <Icon type={getUnreadMsg(state)?.count > 0 || getUnreadMsg(state)?.isRead? "chat-active":"chat"} color={activePage === "messages" ? colors.primary : colors.background} size={0.4 * headerSize} />
          </IconOption>
          <UserOption
            active={activePage === "profile"}
            onMouseEnter={() => setProfileDropdownShown(true)} onMouseLeave={() => setProfileDropdownShown(false)}
          >
            <ProfileDropdown visible={profileDropdownShown}>
              <ProfileOption active={location.pathname === "/profile/account"} onClick={() => {
                navigate("/profile/account");
              }}>
                <Icon type={"user"} color={location.pathname === "/profile/account" ? colors.background : colors.primary} />
                <ButtonSpacer />
                Your Account
              </ProfileOption>
              <ProfileOption active={location.pathname === "/profile/security"} onClick={() => {
                navigate("/profile/security");
              }}>
                <Icon type={"security"} color={location.pathname === "/profile/security" ? colors.background : colors.primary} />
                <ButtonSpacer />
                Login & Security
              </ProfileOption>
              <ProfileBorder />
              <ProfileOption onClick={logOut}>Log Out</ProfileOption>
            </ProfileDropdown>
            <UserImage src={getUserInfo(state).image ? getUserInfo(state).image : `https://avatar.oxro.io/avatar.svg?name=${(getUserInfo(state).firstName).toUpperCase().toUpperCase()}`} />
          </UserOption>
        </> : <>
          <HeaderOption
            onClick={logOut}>
            Log Out
          </HeaderOption>
        </>}
    </HeaderSection>
    {isPremiumOpen &&
      <Modal
      title={'Plans'}
      onClose={()=>setIsPremiumOpen(false)}
      minWidth={'50vw'}
      >
    <PremiumSubscription/>
      </Modal>
    }
  </HeaderContainer>

    <Notifications isOpen={isNotificationsOpen} setIsOpen={setIsNotificationsOpen} />
  </>;
}
export default Header;
