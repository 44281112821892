import user from "./User";
import programsList from "./ProgramsList";
import notifications from "./Notifications";
import messageCount from "./UnreadMessage";
import personalMessageCount from './PersonalMessageCount'
import conversationList from './ConversationList'
import groupConversationList from "./GroupConversationList";
import groupMessageCount from "./GroupMessageCount";
import toast from "./Toasts";


const storeSegments = {
  user,
  programsList,
  notifications,
  toast,
  messageCount,
  personalMessageCount,
  conversationList,
  groupConversationList,
  groupMessageCount
};

export default storeSegments;