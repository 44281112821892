import React, { useEffect } from "react";
import { PillsContainer,Pill, ItemNumber } from "./styles";

// Similar to Tabs
function Pills({options,value,setValue,count,isWidth,isMeetingTab=false}) {

  const RenderComponent = (value) => {
    switch (value) {
      case  "Requests":
        return count?.newRequests ;
      case "Upcoming":
        return count?.upComingRequests
      case "Past":
        return count?.pastRequests;
      case "Archived":
        return count?.archivedRequests ;
    }
  };

  return <PillsContainer isWidth={isWidth} isMeetingTab={isMeetingTab}>
     {
      options && options.map(option => 
        <Pill 
        isWidth={isWidth}
        key={option?.value} active={value===option?.value} onClick={()=>{
          setValue(option?.value);
        }}>{option.label}
   {RenderComponent(option?.value) && <ItemNumber>{RenderComponent(option?.value)}</ItemNumber>}
        
        </Pill>
      )
    }
  </PillsContainer>;
}
export default Pills;