import { loginUser } from "../../../Store/User/actions";
import callAPI from "../../../api/apiCaller";
import {
  validateEmail,
  validateNumber,
  validatePassword,
  validatePhoneNumber,
} from "../../../common/utils";

export const LoginPageConfigurations = {
  login: {
    title: "Login",
    initialState: {
      email: "",
      password: "",
    },
    actionText: "Login",
    form: [
      {
        label: "Email",
        placeholder: "Enter your email",
        key: "email",
        validator: validateEmail,
        type: "text",
      },
      {
        label: "Password",
        key: "password",

        placeholder: "Enter your password",
        validator: (newValue) => {
          return newValue && newValue.length != 0;
        },
        type: "password",
      },
    ],
    action: (setIsProcessing, form, dispatch, setMode,navigate) => {
      setIsProcessing(true);
      let request = {
        email: (form.email)?.toLowerCase(),
        password: form.password, //.toString('base64')
      };
      callAPI(dispatch, "login", request)
        .then((retrievedUser) => {
          setIsProcessing(false);
          if (retrievedUser.isResponseOk) {
            dispatch(loginUser(retrievedUser));
            // navigate("/")
          }
          else if(retrievedUser.message=="Account verification is pending"){
            setMode("verification")
          }
        })
        .catch((err) => {
          console.error(err);
          setIsProcessing(false);
        });
    },
  },
  inializeResetPassword: {
    title: "Reset Password",
    initialState: {
      email: "",
    },
    actionText: "Reset Password",
    form: [
      {
        label: "Email",
        placeholder: "Enter your email",
        key: "email",
        validator: validateEmail,
        type: "text",
      },
    ],
    action: (setIsProcessing, form, dispatch, setMode, navigate) => {
      setIsProcessing(true);
      let request = {
        email: (form.email)?.toLowerCase(),
      };
      callAPI(dispatch, "sendForgotPasswordEmail", request)
        .then((res) => {
          setIsProcessing(false);
          // setMode("login")
          setMode("inializeResetPassword");
        })
        .catch((err) => {
          console.error(err);
          setIsProcessing(false);
        });
    },
  },
  createUser: {
    title: "Create New Account",
    actionText: "Sign Up",
    initialState: {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      phoneNumber: "1234234112423",
    },
    form: [
      {
        label: "Email",
        placeholder: "Enter your email",
        key: "email",
        validator: validateEmail,
        type: "text",
      },
      {
      

        label: "Password",
        key: `password`,
        placeholder: "Enter your password",
        validator: (newValue) => {
          return validatePassword(newValue);
        },
        type: "password",
      },
      {
        hideInstruction:true,
        label: "Confirm Password",
        key: "confirmPassword",
        placeholder: "Confirm your password",
        validator: (newValue, form) => {
          return validatePassword(newValue) && form.password === newValue;
        },
        type: "password",
      },
      {
        label: "First Name",
        key: "firstName",
        placeholder: "Enter your first name",
        validator: (newValue) => {
          return newValue && newValue.length != 0;
        },
        type: "text",
      },
      {
        label: "Last Name",
        key: "lastName",
        placeholder: "Enter your last name",
        validator: (newValue) => {
          return newValue && newValue.length != 0;
        },
        type: "text",
      },
      {
        label: "Date of Birth",
        key: "dateOfBirth",
        placeholder: "Enter your date of birth",
        validator: (newValue) => {
          return true;
        },
        type: "date",
      },
      {
        label: "Phone Number",
        key: "phoneNumber",
        placeholder: "Enter your Phone Number",
        validator: validatePhoneNumber,
        type: "phonenumber",
      },
    ],
    action: (setIsProcessing, form, dispatch, setMode, navigate) => {
      form.email=(form.email)?.toLowerCase()
      setIsProcessing(true);
      let request = {
        ...form,
        address: "",
      };
      callAPI(dispatch, "createUser", request)
        .then((response) => {
          setIsProcessing(false);
          if (response["isResponseOk"]) {
            setMode("login");
          }
        })
        .catch((err) => {
          console.error(err);
          setIsProcessing(false);
        });
    },
  },

  resetPassword: {
    title: "Reset Password",
    initialState: {
      password: "",
      confirmPassword: "",
      id: "",
    },
    actionText: "Reset Password",
    form: [
      {
        ifo:"(Minimum of 8 characters, include at least one special character and at least 1 number)",
        label: "Password ",
        key: `password`,
        placeholder: "Enter your password",
        validator: (newValue) => {
          return validatePassword(newValue);
        },
        type: "password",
      },
      {
        label: "Confirm Password",
        key: "confirmPassword",
        placeholder: "Confirm your password",
        hideInstruction:true,

        validator: (newValue, form) => {
          return validatePassword(newValue) && form.password === newValue;
        },
        type: "password",
      },
    ],
    action: (setIsProcessing, form, dispatch, setMode, navigate) => {
      setIsProcessing(true);
      let request = {
        password: form.password,
        confirmPassword: form.confirmPassword,
      };
      callAPI(dispatch, "forgotPassword", { id: form.id, ...request })
        .then(() => {
          setMode("login");
          navigate("/");
          setIsProcessing(false);
        })
        .catch((err) => {
          console.error(err);
          setIsProcessing(false);
        });
    },
  },

  verification: {
    title: "Verify Your Account",
    actionText: "Verify Your Email",
  
    
    form:[
      {
        label: "Email",
        placeholder: "Enter your email",
        key: "email",
        validator: validateEmail,
        type: "text",
      },
    ],
    initialState: {
      id: "",
      email:""
    },

    action: (setIsProcessing, form, dispatch, setMode, navigate) => {
      setIsProcessing(true);
      const payload={
        token: form.id ,
        email: (form.email)?.toLowerCase() 
      }
      callAPI(dispatch, payload.email ?  "resendEmail" :"verifyAccount", payload)
        .then(() => {
          setMode("login");
          navigate("/");
          setIsProcessing(false);
        })
        .catch((err) => {
          console.error(err);
          setIsProcessing(false);
        });
    },
  },

  
};
