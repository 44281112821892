import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../../../../api/apiCaller";
import Button from "../../../../../../../common/Button";
import { ButtonSpacer } from "../../../../../../../common/Button/styles";
import Loading from "../../../../../../../common/Loading";
import Pills from "../../../../../../../common/Pills";
import Notes from "./Notes";
import { useLocation, useSearchParams  } from 'react-router-dom'
import {
  ConnectionProfileContainer,
  ConnectionProfileHeader,
  ProfileImage,
  ProfileInfo,
  ProfileHeader,
  ProfileName,
  ProfileDescription,
  ProfileActions,
  PillsContainer,
  BackButton
} from "./styles";
import Tasks from "./Tasks";
import Timesheet from "./Timesheet";
import Updates from "./Updates";
import { Context } from "../../../../../../../Store";
import features from "../../../../../../../config/features";
import Boards from "./Collaborate";
import MeetingsTab from "./MeetingsTab";


function ConnectionProfile() {
  const [searchParams] = useSearchParams();
  let params = useParams();
  let navigate = useNavigate();
  const [profile, setProfile] = React.useState(null);
  const { state, dispatch } = React.useContext(Context);
  const role = searchParams.get('role')
  let recipientId=searchParams.get('recipientId')

  const userId=params.id ? params.id : params.connectionId
  React.useEffect(() => {
    callAPI(dispatch, role==='mentor' ? "getSelectedMentee" :"getSelectedMentor", { id: params.connectionId }).then((connectionProfile) => {
      if(connectionProfile.isResponseOk){
        setProfile(connectionProfile.data);
      }
    });
  }, []);
  if (profile === null) {
    return <Loading loadingItem="Connection Profile" />;
  }
  return <ConnectionProfileContainer>
    <ConnectionProfileHeader>
    <BackButton>
      <Button icon="back" onClick={() => { navigate(`/programs/${params.selectedProgram}/${params.tab}`) }} />
    </BackButton>
      <ProfileImage src={profile?.image?profile?.image:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} />
      <ProfileInfo>
        <ProfileHeader>
          <ProfileName>{profile?.firstName+" "+profile?.lastName}</ProfileName>
          <ProfileDescription>{profile?.bio}</ProfileDescription>
        </ProfileHeader>
        <ProfileActions>
          <Button icon="chat" onClick={() => { navigate("/messages/" + profile?.uuid) }} />
          <ButtonSpacer />
          <Button icon="calendar" onClick={() => {
           navigate('/programs/added-to-calendar');
          }} />
        </ProfileActions>
      </ProfileInfo>
    </ConnectionProfileHeader>
    <PillsContainer 
    
    >
      <Pills 
        isWidth={true}

      options={[

        ...(features.updates ? [{
          label: "Updates",
          value: "updates"
        }] : []),
      
      
        ...(features.meetings ? [{
          label: "Meetings",
          value: "meetings"
        }] : []),
        ...(features.collaborate ? [{
          label: "Collaborate",
          value: "collaborate"
        }] : []),
        ...(features.notes ? [{
          label: "Personal Notes",
          value: "notes"
        }] : []),
        ...(features.timesheet ? [{
          label: "Timesheet",
          value: "timesheet"
        }] : []),
        {
          label: "Tasks",
          value: "tasks"
        },
      ]}
        value={params.connectionTab}
        setValue={(newValue) => {
          navigate(`/programs/${params.selectedProgram}/${params.tab}/${params.connectionId}/${newValue}?role=${role}`);
        }}
      />
    </PillsContainer>
    {
      features.updates && params.connectionTab === "updates" && <Updates />
    }
    {
      features.notes && params.connectionTab === "notes" && <Notes />
    }
    {
      features.timesheet && params.connectionTab === "timesheet" && <Timesheet  />
    }
    {
      params.connectionTab === "tasks" && <Tasks />
    }
    {
      features.collaborate && params.connectionTab === "collaborate" && <Boards />
    }
    {
      features.meetings && params.connectionTab === "meetings" && <MeetingsTab />
    }

  </ConnectionProfileContainer>;
}
export default ConnectionProfile;