import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./pages/App";
import HomePage from "./pages/HomePage";
import { Provider } from "./Store";
import { OneMauveContainer } from "./styles";
import Toast from "./common/Toast";

/**
 * 
 * To expand with marketing webpage routing
 */
function OneMauve() {
  if (window.location.pathname.includes("/app")) {
    return <OneMauveContainer>
      <Provider>
        <Toast />
        <BrowserRouter basename="app">
          <App />
        </BrowserRouter>
      </Provider>

    </OneMauveContainer>;
  }
  else {
    return <OneMauveContainer>
      <HomePage />
    </OneMauveContainer>
  }
}
export default OneMauve;