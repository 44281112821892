import React from "react";
import { ButtonSpacer } from "../../../common/Button/styles";
import ProgramContent from "./ProgramContent";
import ProgramsList from "./ProgramsList";
import { ProgramsContainer } from "./styles";

function Programs() {
const [hamburgerOpen, setHamburgerOpen]= React.useState(false)
return <ProgramsContainer>
    <ProgramsList setHamburgerOpen={setHamburgerOpen} hamburgerOpen={hamburgerOpen}/>
    <ProgramContent setHamburgerOpen={setHamburgerOpen} hamburgerOpen={hamburgerOpen}/>
  </ProgramsContainer>;
}
export default Programs;