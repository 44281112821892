import styled from "styled-components";
import colors from "../../config/colors";

export const TableContainer = styled.table`
    width: 100%;
    border:solid 1px ${colors.secondary};
    height:100%;
    border-radius:0px 0px 10px 10px;
    border-spacing: 0;
    min-width: 670px;

    @media (max-width: 991px) {
    min-width: 810px;
    
    }
   

`;
export const TableHead = styled.tr`
    background-color: ${colors.secondary};
`;
export const TableHeaderCell = styled.th`
    padding-top:10px;
    padding-bottom:10px;
    font-weight:700;
    fond-size:12px;
    
    ${props=>props.leftHeader && "text-align:left;"};
    :last-child {
   padding-left:20px
    }
`;
export const TableHeaderCellContent = styled.div`
    user-select: none;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    text-align:left;
   
`;
export const TableBody = styled.tbody`
    :last-child {
        border-radius:0px 0px 10px 10px;
    }
`;
export const TableRow = styled.tr`
:last-child td{
    border:none;
}
`;
export const TableCell = styled.td`
    text-align:left;
    height:40px;
    align-items:center;
    border-bottom:solid 1px ${colors.secondary};
    font-size:14px;
    width:10%;
    :nth-child(1){
        width:2%;
        }
`;
export const TableActions = styled.div`
    padding:0px 5px;
    display:flex;
    align-items:center;
    justify-content:flex-start;
`;