import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import colors from "../../../../../../config/colors";
import { ProgramsListItemContainer, ItemSection, ProgramImage, ProgramName, GrabHandle, ItemNumber } from "./styles";
import callAPI from "../../../../../../api/apiCaller";
import { Context } from "../../../../../../Store";

function ProgramsListItem({ program,setHamburgerOpen,hamburgerOpen }) {
  let { selectedProgram } = useParams();
  let navigate = useNavigate();
  const { dispatch } = React.useContext(Context);


  let isActive = selectedProgram == program.id;
  return <ProgramsListItemContainer
    isActive={isActive}
    onClick={() => {
      navigate("/programs/" + program.id+"/home")
      setHamburgerOpen(!hamburgerOpen)
      

    }}
    draggable={true}
    onDragStart={(e) => {
      e.dataTransfer.setData("text", program.id);
    }}
    title={program.title}
    >
    <ItemSection>
      <ProgramImage src={program.image} />
      <ProgramName>{program.title}</ProgramName>
    </ItemSection>
   {program?.newRequests && program?.programType !=='1_1_NETWORK' &&  <ItemNumber>{program?.newRequests}</ItemNumber>}
    <GrabHandle>
      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.41464 2.53125C4.67353 2.53125 4.88339 2.32138 4.88339 2.0625C4.88339 1.80362 4.67353 1.59375 4.41464 1.59375C4.15576 1.59375 3.94589 1.80362 3.94589 2.0625C3.94589 2.32138 4.15576 2.53125 4.41464 2.53125Z" stroke={isActive ? colors.background : colors.primary} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.41464 5.96875C4.67353 5.96875 4.88339 5.75888 4.88339 5.5C4.88339 5.24112 4.67353 5.03125 4.41464 5.03125C4.15576 5.03125 3.94589 5.24112 3.94589 5.5C3.94589 5.75888 4.15576 5.96875 4.41464 5.96875Z" stroke={isActive ? colors.background : colors.primary} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.41464 9.40625C4.67353 9.40625 4.88339 9.19638 4.88339 8.9375C4.88339 8.67862 4.67353 8.46875 4.41464 8.46875C4.15576 8.46875 3.94589 8.67862 3.94589 8.9375C3.94589 9.19638 4.15576 9.40625 4.41464 9.40625Z" stroke={isActive ? colors.background : colors.primary} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.53696 2.53125C7.79584 2.53125 8.00571 2.32138 8.00571 2.0625C8.00571 1.80362 7.79584 1.59375 7.53696 1.59375C7.27807 1.59375 7.06821 1.80362 7.06821 2.0625C7.06821 2.32138 7.27807 2.53125 7.53696 2.53125Z" stroke={isActive ? colors.background : colors.primary} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.53696 5.96875C7.79584 5.96875 8.00571 5.75888 8.00571 5.5C8.00571 5.24112 7.79584 5.03125 7.53696 5.03125C7.27807 5.03125 7.06821 5.24112 7.06821 5.5C7.06821 5.75888 7.27807 5.96875 7.53696 5.96875Z" stroke={isActive ? colors.background : colors.primary} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.53696 9.40625C7.79584 9.40625 8.00571 9.19638 8.00571 8.9375C8.00571 8.67862 7.79584 8.46875 7.53696 8.46875C7.27807 8.46875 7.06821 8.67862 7.06821 8.9375C7.06821 9.19638 7.27807 9.40625 7.53696 9.40625Z" stroke={isActive ? colors.background : colors.primary} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </GrabHandle>
  </ProgramsListItemContainer>;
}
export default ProgramsListItem;