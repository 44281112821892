import React, { useState } from "react";
import {
  Action,
  BodyContainer,
  MeetingBody,
  MeetingContainer,
  MeetingSidebar,
  MeetingsNotification,
  PillsTeamContainer,
} from "./style";
import Button from "../../../../../../../../common/Button";
import Tabs from "../../../../../../../../common/Tabs";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../../../../Store";
import callAPI from "../../../../../../../../api/apiCaller";
import { OpenNetworkProgramTabs } from "../../../../ProgramRequests/config";
import Pills from "../../../../../../../../common/Pills";
import ProgramInBounds from "../../../../ProgramInBounds";
import ProgramOutBonds from "../../../../ProgramOutBounds";
import { PillsContainer } from "../styles";
import NewRequestForm from "../../../../NewRequest";
import Modal from "../../../../../../../../common/Modal";
import ProgramManager from "./ProgramManager";
import { ChatWindowContainer } from "../../../../../../Messages/ChatWindow/styles";
import Loading from "../../../../../../../../common/Loading";

const MeetingsTab = ({teamId,isTeam}) => {
  const [program, setProgram] = React.useState(null);
  const [newMeeting, setNewMeeting] = React.useState(false);
  const [programMeetingCount, setProgramMeetingCount] = React.useState([]);
  const [MeetingTabCount, setMeetingTabCount] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState("Upcoming");
  const [currentTeamTab, setCurrentTeamTab] = React.useState("manager");
  const [refresh, setRefresh] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const params = useParams();



  function retrieveProgramInformation() {
    setProgram(null);
    params.selectedProgram &&
      callAPI(dispatch, "getProgramInformation", {
        id: params.selectedProgram,
      }).then((info) => {
        setProgram(info.data);
      });
  }

  function specificProgramMeetingsStatistics() {
    callAPI(dispatch, "specificProgramMeetingsCount", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgramMeetingCount(info.data);
    });
  }

  function allProgramMeetingStatistics() {
    callAPI(dispatch, "allProgramsMeetingsCount", {}).then((info) => {
      setMeetingTabCount(info.data);
    });
  }

  React.useEffect(() => {
    if (params.selectedProgram) {
      specificProgramMeetingsStatistics();
      retrieveProgramInformation();
    } else {
      allProgramMeetingStatistics();
    }
    return () => {
      setProgram(null);
    };
  }, []);

  let options = "";
  if(program?.programType === "TEAM"){

    options = OpenNetworkProgramTabs["TEAM"]["dashboard"];
  }
  if(program?.role === "mentor"){
  options = OpenNetworkProgramTabs['ONE_ONE_NETWORK']["dashboard"];
  }else{
  options = OpenNetworkProgramTabs['ONE_ONE_NETWORK']["mentee"];

  }


  const RenderTeamComponent = (currentTeamTab) => {
    // eslint-disable-next-line default-case
    switch (currentTeamTab) {
    case "manager":
      return (
        <ProgramManager/>
      );
      case "ALL_MEMBERS_AND_MENTOR":
        return (
         <NewRequestForm isTeam={program?.programType === "TEAM"} currentTeamTab={currentTeamTab} setNewMeeting={setNewMeeting}/>
        );
      case "allmembers":
        return (
         <NewRequestForm isTeam={program?.programType === "TEAM"} currentTeamTab={currentTeamTab} setNewMeeting={setNewMeeting}/>
        );
    }
  };
  const RenderComponent = (currentTab) => {
    // eslint-disable-next-line default-case
    switch (currentTab) {
      case "Requests":
        return (
          <ProgramInBounds
            isUpcoming={true}
            // isTeam={true}
            isTeam={program?.programType === "TEAM"}
            currentTab={currentTab}
            allProgramMeetingStatistics={allProgramMeetingStatistics}
            refresh={refresh}
            specificProgramMeetingsStatistics={
              specificProgramMeetingsStatistics
            }
          />
        );
      case "Upcoming":
        return (
          <ProgramOutBonds
            isUpcoming={true}
            // isTeam={true}
            isTeam={program?.programType === "TEAM"}
            currentTab={currentTab}
            allProgramMeetingStatistics={allProgramMeetingStatistics}
              refresh={refresh}
            specificProgramMeetingsStatistics={
              specificProgramMeetingsStatistics
            }
          />
        );
      case "Past":
        return (
          <ProgramOutBonds
            currentTab={currentTab}
            isPast={true}
            // isTeam={true}
            isTeam={program?.programType === "TEAM"}
            allProgramMeetingStatistics={allProgramMeetingStatistics}
            specificProgramMeetingsStatistics={
              specificProgramMeetingsStatistics
            }
          />
        );
    }
  };


  
  return (
    <>
    <MeetingContainer>
    {program ===null ? 
    <MeetingSidebar>
    <Loading loadingItem="Meeting Tabs" />
    </MeetingSidebar>
    :
     <MeetingSidebar isTeam={program?.programType === "TEAM"}>
       {program?.role === "mentee" && program?.programType !== "TEAM" && <Action>
          <Button text={"New Meeting"} icon={"add"} onClick={()=>setNewMeeting(true)}   />
        </Action>}
       {program?.programType === "TEAM" && <Action>
          <Button text={"New Meeting"} icon={"add"} onClick={()=>setNewMeeting(true)}   />
        </Action>}
        <PillsTeamContainer>
          {options?.length && (
            <Pills
              value={currentTab}
              setValue={setCurrentTab}
              options={options}
              count={programMeetingCount}
              isMeetingTab={true}
            />
          )}
        </PillsTeamContainer>
      </MeetingSidebar>}
      <MeetingBody isWidth={true}>
        <BodyContainer isTeam={program?.programType === "TEAM"}>
          <MeetingsNotification >
            {RenderComponent(currentTab)}
          </MeetingsNotification>
        </BodyContainer>
      </MeetingBody>
    </MeetingContainer>
    {newMeeting && program?.programType === "TEAM" ? (
      <Modal onClose={() => setNewMeeting(false)}>
        <Tabs 
         value={currentTeamTab}
         setValue={setCurrentTeamTab}
        options={[
      {
        label: "Program Manager(s)",
        value: "manager"
      },
      {
        label: "Team Leader(s)",
        value: "ALL_MEMBERS_AND_MENTOR"
      },
      {
        label: "Members",
        value: "allmembers"
      }
    ]}/>
        {RenderTeamComponent(currentTeamTab)}
      </Modal>
    ):newMeeting && program?.programType !== "TEAM"?
    <Modal onClose={() => setNewMeeting(false)}>
    <NewRequestForm setNewMeeting={setNewMeeting}
setRefresh={setRefresh}

    />
    </Modal>:''
  
    }
    </>
  );
};

export default MeetingsTab;
