import styled from "styled-components";
import colors from "../../config/colors";

export const CheckboxContainer = styled.div`
    display:flex;
    align-items:center;
    transition: all 0.1s ease-in-out;
`;
export const TheBox = styled.div`
    margin-right:10px;
    height:20px;
    width:20px;
    display:flex;
    font-size:14px;
    align-items:center;
    justify-content:center;
    border:${props =>props.isBorder ?'solid 1px #a7a2a2' :'solid 1px ${colors.secondary}'};
    border-radius:5px;
    cursor:${props=>props.disabled?"not-allowed":"pointer"};
    background-color:${props=>props.checked?props.disabled ? colors.darkBackground:colors.primary:colors.background};
    :active{
        transform:scale(0.95);
    }
`;