import { useEffect, useState, useContext } from "react";
import {
    TextInputContainer,
    Input,
    ContactImage,
    ContactName,
    DropdownContainer,
    DropdownItem,
    ContactInfo,
    SearchableChatContainer,
    UserNotFound,
    UserNotFoundText
} from "./styles";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import { getConversationsList } from "../../Store/ConversationList/accessors";
import { Context } from "../../Store";


export const SearchTextInput = ({
    SearchableItemClick,
    placeholder = "Placeholder",
    disabled = false,
    wordLimit = 0,
    validator = () => true,
    isValid = null,
    isTextArea = false,
    isNotRound=false,
    setIsValid = () => {},
    isHeight=false
    }) => {
    const params = useParams();
    const [isDropdown, setIsDropdown] = useState(false);
    const [value , setValue] = useState('');
    const { state } = useContext(Context);
    const [filterConversations, setFilteredConversation] = useState(getConversationsList(state).messages);
    const [contactNotFound, setContactNotFound] = useState(false);


    useEffect(() => {
        const filtering = () => {
            if(getConversationsList(state).messages){
                const filteredMessages = getConversationsList(state).messages.filter((message) => {
                    const receiverName = `${message.receiver.firstName} ${message.receiver.lastName}`;
                    return (
                    receiverName.toLowerCase().startsWith(value.toLowerCase()) ||
                    message?.lastMessage?.message
                        .toLowerCase()
                        .startsWith(value.toLowerCase())
                    );
                });
                if(filteredMessages.length > 0){
                    setFilteredConversation(filteredMessages);
                    setContactNotFound(false);
                }else{
                    setContactNotFound(true);
                }
            }
        }
        filtering();
    },[value,state])

    return(
        <SearchableChatContainer>
        <TextInputContainer>
            <Input
                isValid={isValid}
                value={value}
                placeholder={isNotRound ? "" : placeholder}
                type={"text"}
                isTextArea={isTextArea}
                isHeight={isHeight}
                disabled={disabled}
                onChange={(e) => {
                if (disabled) {
                    return;
                }
                setValue(e.target.value);
                if(e.target.value <= 0){
                    setIsDropdown(false)
                } else{
                    setIsDropdown(true);
                }
                setIsValid(
                    (wordLimit !== 0 ? e.target.value.length <= wordLimit : true) &&
                    validator(e.target.value)
                );
                }}
                rows={4}
                cols={50}
                min={new Date().toISOString().slice(0, 16)}
            />
        </TextInputContainer> 
                {isDropdown && 
                    <>
                <DropdownContainer>
                        {!filterConversations ? <Loading loadingItem="Contacts" /> : !contactNotFound ? filterConversations && filterConversations.map((item) => {
                            return (
                                <DropdownItem key={item.id} onClick={() => {
                                    SearchableItemClick(item)
                                    setIsDropdown(false);
                                    setValue('');
                                }}>
                                    <ContactInfo>
                                        <ContactImage  src={
                                            item.receiver.id === params.id ?
                                            item.lastMessage.sender.image 
                                            : item.receiver.image ?
                                            item.receiver.image 
                                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} 
                                            alt="default" 
                                        />
                                        <div>
                                            <ContactName>
                                                {item.receiver.id === params.id ?
                                                `${item.lastMessage.sender.firstName} ${item.lastMessage.sender.lastName}` 
                                                : `${item.receiver.firstName} ${item.receiver.lastName}`}
                                            </ContactName>
                                        </div>
                                    </ContactInfo>
                                </DropdownItem>
                            )
                        }) : <UserNotFound><UserNotFoundText>User not found</UserNotFoundText></UserNotFound>}
                </DropdownContainer> 

            </>
        }
        </SearchableChatContainer>
    )
}