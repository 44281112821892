import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import { Context } from "../../../../../Store";
import { refreshProgramList } from "../../../../../Store/ProgramsList/actions";
import EditProgramManagers from "../../popups/EditProgramManagers";
import SendAnnouncement from "../../popups/SendAnnouncement";
import Announcements from "./Announcements";
import {
  ProgramHomeContainer,
  ProgramHomeHeader,
  ProgramImage,
  ProgramInfo,
  ProgramManagerImage,
  ProgramManagers,
  ProgramManagersLabel,
  ProgramManagersList,
  ProgramName,
  ProgramOrganization,
  ProgramTrackedHours,
  AboutContent,
  AboutTitle,
  AboutDescription,
  AnnouncementsContent,
  AnnouncementsTitle,
  EditModeOptions,
  LinkLine,
  Link
} from "./styles";
import ApplicationEditorModal from "../../popups/ApplicationEditorModal";
import features from "../../../../../config/features";
import EditProgram from "../../popups/EditProgram";
import AddParticipants from "../../popups/AddParticipants";
import ApplicationModal from "../../popups/ApplicationModal";
import { convertToLinks, formatDescription, formatsDescription } from "../../../../../common/utils";
import { getUserInfo } from "../../../../../Store/User/accessors";
import Modal from "../../../../../common/Modal";
import { SectionActions } from "../../../Profile/LoginSecurity/styles";
import NewRequestForm from "../NewRequest";
import Payment from "./Payment";
 
const ADMIN_ROLES = [
  "manager",
  "member_manager",
  "mentor_manager"
]

function ProgramHome({ program, refreshProgram }) {
  let params = useParams();
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showEditProgram, setShowEditProgram] = React.useState(false);
  const [showEditProgramManagers, setShowEditProgramManagers] = React.useState(false);
  const [isDeletingProgram, setIsDeletingProgram] = React.useState(false);
  const [showSendAnnouncement, setShowSendAnnouncement] = React.useState(false);
  const [showApplicationEditor, setShowApplicationEditor] = React.useState(false);
  const editMode = ADMIN_ROLES.includes(program.role);
  const { state, dispatch } = React.useContext(Context);
  const [isAddParticipantsOpen,setIsAddParticipantsOpen] = React.useState(false);
  const [hasCopied,setHasCopied] = React.useState(false)
  const [isApplicationOpen,setIsApplicationOpen] = React.useState(false);
  const [isApplicationOpenforApply,setIsApplicationOpenforApply] = React.useState(false);
  const [isSuperCharge,setIsSuperCharge] = React.useState(false);
  function deleteProgram() {
    setIsDeletingProgram(true);
    callAPI(dispatch, "deleteProgram", { id: params.selectedProgram }).then(() => {
      setIsDeletingProgram(false);
      navigate("/programs");
      dispatch(refreshProgramList()) 
    });
  }

  function closeModal(){
    setIsModalOpen(false)
  }
  
  let url = window.location.protocol + "//" + window.location.host + "/app/programs/"+program.id+"/home";
  return <ProgramHomeContainer>
    <ProgramHomeHeader>
      <ProgramImage src={program.image} />
      <ProgramInfo>
        <ProgramName>{program.title}</ProgramName>
        <ProgramOrganization>{program.organization}</ProgramOrganization>
        <ProgramManagers>
          <ProgramManagersLabel>Program Managers</ProgramManagersLabel>
          <ProgramManagersList>
            {
              program.managers.map((manager) => {
                return <><ProgramManagerImage key={manager.id} src={manager.user.image?manager.user.image:'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'} onClick={() => {
                getUserInfo(state).id===manager.userId ? navigate(`/profile/account`): navigate(`/messages/${manager.user.uuid}`)
                }} /><ButtonSpacer /></>
              })
            }
          </ProgramManagersList>
        
        </ProgramManagers>
        <ProgramTrackedHours>Total Tracked Hours: {program.totalTrackHours}</ProgramTrackedHours>
        <LinkLine>
    <Link href={url}>{url}</Link>
    <Button icon={hasCopied ? "tick" : "copy"} text="Copy" onClick={()=>{
        navigator.clipboard.writeText(url)
        setHasCopied(true)
    }}/>
    </LinkLine>
            <ButtonSpacer/>
    {
    <>
    {
      // program.programType==="1_1_NETWORK" &&
       program.enrollment && program.enrollment !== "active"
        &&
      <Button 
      text={program.enrollment === "not-enrolled" ?  "Apply" : "Requested"}
      icon={program.enrollment === "not-enrolled" ?  "tick" : "time"}
      variant="primary"
      disabled={program.enrollment !== "not-enrolled"}
      onClick={()=>setIsApplicationOpenforApply(true)}
      /> 
  }

    {
      program.role ==='mentee' && program.programType !== '1_1_NETWORK'  &&
      // the button only show to mentee not mentor
       <Button 
      text={  "Request a Meeting"}
      // icon={program.enrollment === "not-enrolled" ?  "tick" : "time"}
      variant="primary"
      icon={'calendar'}
      // disabled={program.enrollment !== "not-enrolled"}
      onClick={()=>setIsApplicationOpen(true)}
      /> 
    }
   
    {
      program.role==="manager" &&
       <Button 
      text={  "Supercharge"}
      // icon={program.enrollment === "not-enrolled" ?  "tick" : "time"}
      variant="primary"
      // disabled={program.enrollment !== "not-enrolled"}
      onClick={()=>setIsSuperCharge(true)}
      /> 
    }
    {
     program.role==="mentor_manager" &&
       <Button 
      text={  "Supercharge"}
      // icon={program.enrollment === "not-enrolled" ?  "tick" : "time"}
      variant="primary"
      // disabled={program.enrollment !== "not-enrolled"}
      onClick={()=>setIsSuperCharge(true)}
      /> 
    }
    
   {isSuperCharge &&( <Modal
    onClose={()=>setIsSuperCharge(false)}
    >
      <Payment  ismodalOpen={isSuperCharge} setIsmodalOpen={setIsSuperCharge} title={program?.title} program={program}/>
    </Modal>)}
      </>
    }
      </ProgramInfo>
    </ProgramHomeHeader>
    {
      editMode && <EditModeOptions>
        <Button icon="edit" text="Edit Program" onClick={() => { setShowEditProgram(true) }} />
        <ButtonSpacer />
        <Button icon="edit" text="Edit Program Managers" onClick={() => { setShowEditProgramManagers(true) }} />
        <ButtonSpacer />
        <Button icon="edit" text="Edit Program Application" onClick={() => { setShowApplicationEditor(true) }} />
        <ButtonSpacer />
        <Button icon="add" text="Add Participants" onClick={() => { setIsAddParticipantsOpen(true) }} />
        <ButtonSpacer />
        
        {features.announcements && <>
          <Button icon="send" text="Send Announcement" variant={"primary"}  onClick={() => { setShowSendAnnouncement(true) }} />
          <ButtonSpacer />
        </>}
        
        <Button icon={isDeletingProgram ? "loading" : "trash"} variant={"danger"} text="Delete Program" onClick={() => {
          setIsModalOpen(true)
        }} />

{isModalOpen  && <Modal
        title={"Are you sure you want to proceed?"}
        isDelete={true}
        onClose={
          closeModal
          // setShowEditProgramManagers(false)
        }
      >
        <SectionActions>
        <Button icon={ isDeletingProgram ? "loading" :"trash"} variant="danger" onClick={deleteProgram} text="Delete Program" />
        <ButtonSpacer />
        <Button icon="tick" variant="primary" onClick={()=>setIsModalOpen(false)}  text="Cancel" />

      </SectionActions>

        {/* <EditProgramManagersContainer>
        <Loading item="Program Managers" />
        </EditProgramManagersContainer>; */}
      </Modal> 
}
      </EditModeOptions>
    }
    <AboutContent>
      <AboutTitle>About</AboutTitle>
      <AboutDescription>
      <div dangerouslySetInnerHTML={{ __html: convertToLinks(formatsDescription(program.description)) }}/>
    </AboutDescription>
    </AboutContent>
    {features.announcements && program.enrollment && program.enrollment === "active" && <AnnouncementsContent>
      <AnnouncementsTitle>Announcements</AnnouncementsTitle>
      <Announcements editMode={editMode} refreshProgram={refreshProgram}/>
    </AnnouncementsContent>}
    {
      showEditProgram 
      
    &&<>
      <EditProgram programId={params.selectedProgram} setShowAddEditProgram={(shouldRefresh) => {
        shouldRefresh && refreshProgram();
        setShowEditProgram(false);
      }} />
      </>
    }
   
    {
      showEditProgramManagers && <EditProgramManagers editProgram={params.selectedProgram} setShowEditProgramManagers={(shouldRefresh) => {
        shouldRefresh && refreshProgram()
        setShowEditProgramManagers(false)
      }} />
    }
    {
      showSendAnnouncement && <SendAnnouncement announcementProgram={params.selectedProgram} closeModal={(refresh)=>{
        if(refresh){
          refreshProgram();
        }
        setShowSendAnnouncement(false)
      }} />
    }
    {
      showApplicationEditor && <ApplicationEditorModal programId={params.selectedProgram} setShowApplicationEditor={setShowApplicationEditor} />
    }
     {
      isAddParticipantsOpen && <AddParticipants  program={program}  closeModal={()=>{
        setIsAddParticipantsOpen(false);
      }}/>
    }
    {
      isApplicationOpenforApply
      //  && program.programType==='1_1_NETWORK' 
      && program.enrollment && program.enrollment !== "active"
       && <ApplicationModal 
      programName={program}
      closeModal={()=>{
        setIsApplicationOpenforApply(false);
      }}/>
    }
    {
      isApplicationOpen && 
      <Modal onClose={() => setIsApplicationOpen(false)}>
          <NewRequestForm  
              setShowForm={setIsApplicationOpen}/>
        </Modal>
    }
  </ProgramHomeContainer>;
}
export default ProgramHome;