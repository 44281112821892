import React from "react";
import {
  ButtonWrapper,
  ButtonsWraper,
  ImagePreviews,
  NoprogramContainer,
  RequestContent,
  RequestListContainer,
  RequestListWrapper,
  RequestsDetailsComment,
  RequestsDetailsWrapper,
  RequestsKeyHeader,
  RequestsKeyHeaders,
  Spacer,
} from "./styles";
import callAPI from "../../../../../api/apiCaller";
import { Context } from "../../../../../Store";
import { useCallback } from "react";
import { HeaderLine } from "../../ProgramsList/styles";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import { RequestButtonContainer } from "../NewRequest/styles";
import { convertToLinks, dateToUnixTimeStamp, unixStampToDate,} from "../../../../../common/utils";
import { useParams } from "react-router-dom";
import Loading from "../../../../../common/Loading";
import { ProgramLoaderContainer } from "../ProgramOutBounds/style";
import NoProgramImage from "../home-program.svg";

function ProgramInBounds({ isArchive, currentTab, allProgramMeetingStatistics,specificProgramMeetingsStatistics,refresh }) {
  const params=useParams()
  const { state, dispatch } = React.useContext(Context);
  const [openNetworkPrograms, setOpenNetworkProgramsRequests] = React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(null)
  const programs = () => {
    callAPI(dispatch, isArchive ? "getOpenNetworkArchiveRequests": "getOpenNetworkRequest", {id:params.selectedProgram}).then((res) => {
      setOpenNetworkProgramsRequests([])
      if (res.isResponseOk) {
        setOpenNetworkProgramsRequests(res.data);
      }
    });
  };

  function acceptMeeting(meetingId, date){
    // console.log(meetingId,"<Meeting");
    callAPI(dispatch, "acceptMeetingRequest", {id: meetingId,meetingScheduleDate:date, status:"ACCEPT"}).then((res) => {
      programs()
      if(params.selectedProgram){
        specificProgramMeetingsStatistics()
      }else{
        allProgramMeetingStatistics()
      }
    });
  }


  
  React.useEffect(() => {
    programs();
     // to render meeting count
     if (params.selectedProgram) {
      specificProgramMeetingsStatistics();
    } else {
      allProgramMeetingStatistics();
    }


    
    return ()=>{
   
      setOpenNetworkProgramsRequests(null)
    }

  }, [currentTab,refresh]);

  console.log(refresh,"refresh");
  const today=new Date()
  const [isValid, setIsValid] = React.useState({
    av1: null,
  });

  if (openNetworkPrograms === null) {
    return<ProgramLoaderContainer> <Loading loadingItem="Requests Meeting"/> </ProgramLoaderContainer>
  }

  function handleRequest(request){
    setIsProcessing(true)
    let payload={}
    if(currentTab==='Requests'){
     payload={
      meetingId: request.meetingId,
      programId: request.programId,
      date:request.date,
      date2:request.date2,
      date3:request.date3,
      status:'ARCHIVE'
     }
    }
    else{
      payload={id: request.userMeetings[0].id}
    }
    callAPI(dispatch,!isArchive ? "handleRequests" : "handleRequestsUndo", {...payload}).then((res) => {
       // to render meeting count
       setIsProcessing(false)
       programs()
      
    if (params.selectedProgram) {
      specificProgramMeetingsStatistics();
    } else {
      allProgramMeetingStatistics();
    }
    });
  }
 
  if (openNetworkPrograms.length === 0) {
    return (
      <NoprogramContainer>
        <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
          You do not currently have any {currentTab==="Requests" ? "Requests" : "Archived"}{" "}
          Meetings.
        </RequestsKeyHeaders>
        <ImagePreviews src={NoProgramImage} />
        <ButtonsWraper>
        </ButtonsWraper> 
      </NoprogramContainer>
    );
  }

  return (
    <>
      {openNetworkPrograms.map((request) => {
        return (
          <RequestListWrapper isMeetingTab={request?.program.programType === 'OPEN_NETWORK'} >
          
            <HeaderLine>
             {isArchive ? request?.reasonForMeeting : request?.meeting?.reasonForMeeting}
            </HeaderLine>
            <RequestsDetailsWrapper>
              <RequestsKeyHeader> Requested By: </RequestsKeyHeader>{" "}
             <RequestContent> {request?.user?.firstName} {request?.user?.lastName}</RequestContent>
            </RequestsDetailsWrapper>

            <RequestsDetailsWrapper>
              <RequestsKeyHeader> Program: </RequestsKeyHeader>{" "}
              <RequestContent> {isArchive ? request?.title : request?.program?.title}</RequestContent>
            </RequestsDetailsWrapper>

            <RequestsDetailsWrapper>
              <RequestsKeyHeader> Location:{" "} </RequestsKeyHeader>{" "}
              <RequestContent> {isArchive ? request?.location :request?.meeting?.location}</RequestContent>
            </RequestsDetailsWrapper>
            <Spacer />
            <RequestsDetailsWrapper>
              <RequestsKeyHeader> Availability: </RequestsKeyHeader>
              <Spacer />
            </RequestsDetailsWrapper>
            <ButtonWrapper>
              <Button
                text={unixStampToDate(request?.date)}
                disabled={dateToUnixTimeStamp(today) > request?.date ? true :false }
                onClick={()=>acceptMeeting(isArchive ?  request.id : request.meetingId , request?.date)
                }
                icon={""}
              />
              <ButtonSpacer />
              <Button
                text={unixStampToDate(request?.date2)}
                disabled={dateToUnixTimeStamp(today) > request?.date2 ? true :false }
                onClick={()=>acceptMeeting(isArchive ?  request.id : request.meetingId, request?.date2)
                  
                }
                icon={""}
              />
              <ButtonSpacer />

              <Button
                text={unixStampToDate(request?.date3)}
                onClick={()=>acceptMeeting(isArchive ?  request.id : request.meetingId, request?.date3)
                }
                disabled={dateToUnixTimeStamp(today) > request?.date3 ? true : false }


                icon={""}
              />
            </ButtonWrapper>
            <Spacer />
            <RequestsDetailsComment>
              <RequestsKeyHeader> Addition Comments: </RequestsKeyHeader>
              {/* <RequestContent>{isArchive ? request?.additionalInfo: request?.meeting?.additionalInfo ? request?.meeting?.additionalInfo :'-'}</RequestContent> */}
              <RequestContent>
  {isArchive
    ? (request?.additionalInfo ? (
        <div dangerouslySetInnerHTML={{ __html: convertToLinks(request.additionalInfo) }} />
      ) : '-')
    : (request?.meeting?.additionalInfo ? (
        <div dangerouslySetInnerHTML={{ __html: convertToLinks(request.meeting.additionalInfo) }} />
      ) : '-')}
</RequestContent>

            </RequestsDetailsComment>
            <RequestButtonContainer>
              <Button text={isArchive ? 'Undo Archive':"Archive Request"} variant="secondary"   
             
              icon={isProcessing ?"loading":""} onClick={()=>handleRequest(request)}/>
            </RequestButtonContainer>
          </RequestListWrapper>
        );
      })}
    </>
  );
}

export default ProgramInBounds;
